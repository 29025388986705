import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";
import SubblockRichText from "../components/common/subblock/subblock-rich-text";
import SubblockHeadlineWithSubtitle from "../components/common/subblock/subblock-headline-with-subtilte";
import SectionLayout from "../components/common/layout/sectionLayout";

const UeberUns = ({ data }) => {
  const header = data.strapiUeberUns.header
  const title = data.strapiUeberUns.aboutUsTitle
  const textLeft = data.strapiUeberUns.aboutUsTextLeft
  const textRight = data.strapiUeberUns.aboutUsTextRight

  return (
    <>
      <Seo title="Über uns" />
      <Layout>
        <SectionLayout>
          <SubblockPageHeader content={header} />
          <SubblockHeadlineWithSubtitle title={title} subTitle="" noBottom />
          <SubblockRichText content={textLeft} position="left" />
          <SubblockRichText content={textRight} position="right" />
        </SectionLayout>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiAboutUsQuery {
  strapiUeberUns {
    header {
      ...fragmentHeader
    }
    aboutUsTitle
    aboutUsTextLeft {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
    aboutUsTextRight {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
  }
}
`

export default UeberUns
